import * as ApolloSIP from './apollosip-web'
import EventEmitter from 'events'

class Apollo extends EventEmitter {
  constructor() {
    super()

    this.ua = undefined

    this.conference = new ApolloSIP.Conference()
    this.conferenceManager = new ApolloSIP.ConferenceManager()
  }

  createUA(options) {
    this.ua = new ApolloSIP.UA(options)
  }
}

const app = new Apollo()

const toplevel = global.window || global

toplevel.Apollo = app

export default app
