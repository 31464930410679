<!--
 * @Author: Leif
 * @Date: 2021-10-25 18:26:15
 * @LastEditors: ch3nh2
 * @LastEditTime: 2021-11-19 11:43:50
 * @FilePath: /zhy_dataview_fe/src/components/InsidePages/UserList.vue
 * @Description: 已选人员列表
-->
<template>
  <div class="userList">
    <div class="participate_row">
      <div class="selected_num">当前人数：{{ userList.length }}</div>
    </div>
    <div class="participated_list">
      <ul>
        <li v-for="(item, index) in userList" :key="index">
          <div class="user_inner">
            <div class="avatar">
              <img :src="require('@/assets/icons/icon_group.png')" alt="" />
            </div>
            <div class="user_cell">
              <div class="username">{{ item.displayText }}</div>
              <div class="phone">{{ item.phone }}</div>
            </div>
            <div class="participated_tool">
              <div class="host_text" v-if="item.isPresenter()">主持人</div>
              <div class="voice"></div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "MonitoringList",
  data() {
    return {};
  },
  computed: {},
  components: {},
  props: {
    userList: {
      type: Array,
      default: [],
    },
  },
  methods: {},
  created() {},
  beforeUnmount() {},
};
</script>

<style lang="scss">
.userList {
  overflow-y: auto;
  .participate_row {
    color: rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    padding: 20px;
  }
  .participated_list {
    height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
    li {
      padding: 20px;
      color: rgba(255, 255, 255, 0.6);
      cursor: pointer;
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
        .user_inner {
          color: #fff;
        }
      }
      .user_inner {
        display: flex;
        align-items: center;
        transition: all 0.2s ease-out;
      }
      .avatar {
        width: 52px;
        height: 52px;
        background: #30354d;
        margin-right: 20px;
        overflow: hidden;
        border-radius: 100%;
        img {
          width: 100%;
        }
      }
      .user_cell {
        line-height: 24px;
        flex: 1;
      }
      .participated_tool {
        display: flex;
        align-items: center;
      }
      .host_text {
        font-size: 14px;
        color: #2584ef;
        margin-right: 10px;
      }
      .voice {
        width: 20px;
        height: 20px;
        background: url(../../assets/icons/icon_voice@2x.png) no-repeat;
        background-size: 100%;
      }
    }
  }
}
</style>
