<!--
 * @Author: Leif
 * @Date: 2021-10-25 18:26:15
 * @LastEditors: ch3nh2
 * @LastEditTime: 2021-11-19 11:50:00
 * @FilePath: /zhy_dataview_fe/src/components/InsidePages/SelectedPeople.vue
 * @Description: 已选人员列表
-->
<template>
  <div class="selectedPeople" v-if="selectedList.length > 0">
    <div class="selected_row">
      <div class="selected_num">已选：{{ selectedList.length }}</div>
      <div class="clear" @click="clearSelected">清空</div>
    </div>
    <div class="selected_list">
      <ul class="selected_list_ul">
        <li v-for="(item, index) in selectedList" :key="index" v-show="item.type == 'STAFF'">
          <div class="selected_user">
            <div class="avatar">
              <img :src="require('@/assets/icons/icon_group.png')" alt="" />
            </div>

            <div class="user_cell">
              <div class="username">{{ item.name }}</div>
              <div class="phone">{{ item.account || "-" }}</div>
            </div>
          </div>
          <div class="close" @click="removeSelected(item)"></div>
        </li>
      </ul>
    </div>
  </div>
  <div class="selected_nodata" v-else>
    <p>当前未选择任何成员</p>
  </div>
</template>

<script>
export default {
  name: "MonitoringList",
  data() {
    return {};
  },
  computed: {},
  components: {},
  props: {
    selectedList: {
      type: Array,
      default: [],
    },
  },
  methods: {
    removeSelected(item) {
      this.$emit("removeSelected", item);
    },
    clearSelected() {
      this.$emit("clearSelected");
    },
  },
  created() {},
  beforeUnmount() {
    this.clearSelected();
  },
};
</script>

<style lang="scss">
.selectedPeople {
  padding: 10px;
  overflow-y: auto;
  .selected_row {
    color: rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    padding: 20px;
    margin: 0 -15px;
    .clear {
      cursor: pointer;
      user-select: none;
      color: red;
      font-weight: bold;
      &:active {
        opacity: 0.5;
      }
    }
  }
  .selected_list {
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: rgba(255, 255, 255, 0.6);
      padding: 20px;
      margin: 0 -15px;
      transition: all 0.2s ease-out;
      cursor: pointer;
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
      .selected_user {
        display: flex;
        align-items: center;
        .avatar {
          width: 52px;
          height: 52px;
          background: #30354d;
          margin-right: 20px;
          overflow: hidden;
          border-radius: 100%;
          img {
            width: 100%;
          }
        }
        .user_cell {
          line-height: 24px;
          max-width: 200px;
        }
      }
      .close {
        width: 20px;
        height: 20px;
        background: url(../../assets/icons/delet_icon@2x.png) no-repeat;
        background-size: 100%;
        &:active {
          opacity: 0.5;
        }
      }
    }
  }
}
.selected_nodata {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}
</style>
