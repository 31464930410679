<!--
 * @Author: Leif
 * @Date: 2021-11-05 13:44:27
 * @LastEditTime: 2022-05-11 10:50:55
 * @LastEditors: ch3nh2
 * @Description: 会议调度 - 会议中
 * @FilePath: /zhy_dataview_fe/src/pages/MeetingSchedule/InMeeting.vue
-->
<template>
  <div class="in_meeting">
    <div class="meeting_room_num">
      <p>会议号码：{{ meeting.number }}</p>
    </div>
    <div class="meeting_room_main">
      <div class="people_list">
        <div class="meeting_room_main_videos">
          <video autoplay class="remote-video" id="remote-video"></video>
          <video muted autoplay class="local-video" style="opacity: 0" id="local-video"></video>
        </div>

        <!-- <ul>
					<li v-for="(item, index) in participantsList" :key="index">
						<div class="participantsItem">
							<div class="avatar">
								<img
									:src="require('assets/icons/icon_user.png')"
									alt=""
								/>
							</div>
							<div class="item_info_row">
								<div class="user_name">
									{{ item.displayText }}
								</div>
								<div class="user_phone">{{ item.phone }}</div>
							</div>
						</div>
					</li>
				</ul> -->
      </div>
      <div class="tool_panel">
        <div class="tool_item" @click="muteAudio">
          <i :class="{
              icon_tool: true,
              icon_mute: true,
              icon_mute_active: !audioMuted,
            }"></i>
          <span>静音</span>
        </div>
        <div class="tool_item" @click="muteVideo">
          <i :class="{
              icon_tool: true,
              icon_camera: true,
              icon_camera_active: !videoMuted,
            }"></i>
          <span>摄像头</span>
        </div>
        <div :class="{
            tool_item: true,
            active: inviteVisible == true ? true : false,
          }" @click="inviteVisible = true">
          <i class="icon_tool icon_invite"></i>
          <span>邀请成员</span>
        </div>
        <div :class="{
            tool_item: true,
            active: UserListVisible == true ? true : false,
          }" @click="UserListVisible = true">
          <i class="icon_tool icon_user_list"></i>
          <span>成员列表</span>
        </div>
        <!-- <div class="tool_item">
					<i class="icon_tool icon_more"></i>
					<span>更多</span>
				</div> -->
        <div class="tool_item" @click="endMeeting">
          <span class="end_meeting">结束</span>
        </div>
      </div>
    </div>
    <!-- 邀请成员面板 -->
    <el-drawer v-model="inviteVisible" custom-class="general_drawer" title="邀请成员" direction="rtl" size="40%" @closed="clearSelected">
      <el-row :gutter="30">
        <el-col :span="12">
          <div class="selected_people_main">
            <SelectedPeople :selectedList="inviteList" @removeSelected="removeSelected" @clearSelected="clearSelected" />
          </div>
        </el-col>
        <el-col :span="12">
          <AddressBookList ref="addressBook" :meeting="true" @checkedNodes="checkedNodes" />
        </el-col>
      </el-row>
      <div class="confirm_row" v-if="inviteList.length > 0">
        <el-button class="invite_btn" type="primary" @click="onInvite">
          邀请
        </el-button>
        <el-button plain class="cancel_btn" @click="inviteVisible = false">
          取消
        </el-button>
      </div>
    </el-drawer>

    <!-- 成员列表面板 -->
    <el-drawer v-model="UserListVisible" custom-class="general_drawer" title="成员列表" direction="rtl" size="25%">
      <UserList :userList="participantsList" />
    </el-drawer>
  </div>
</template>

<script>
import AddressBookList from "components/InsidePages/AddressBookList";
import MonitoringList from "components/InsidePages/MonitoringList";
import MeetingGroupList from "components/InsidePages/MeetingGroupList";
import SelectedPeople from "components/InsidePages/SelectedPeople";
import UserList from "components/InsidePages/UserList";
import apollo from "utils/apollo";
import { config } from "@/config/index";

function getPrefixedProperty(object, name) {
  if (object == null) {
    return function () { };
  }
  const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1);
  const prefixedNames = [
    name,
    `webkit${capitalizedName}`,
    `moz${capitalizedName}`,
  ];

  for (const key in prefixedNames) {
    if (prefixedNames.hasOwnProperty(key)) {
      const prefixedName = prefixedNames[key];
      const property = object[prefixedName];

      if (property) {
        return property.bind(object);
      }
    }
  }
}

export default {
  name: "InMeeting",
  data() {
    return {
      remoteStreamArr: [],
      inviteList: [],
      participantsList: [],
      EventLogTran: false,
      leftActiveName: "addressBook",
      rightActiveName: "InMeeting",
      inviteVisible: false,
      UserListVisible: false,
      // 亿联账号信息
      account: {
        username: "0136",
        password: "123456yl",
      },
      meeting: {
        number: this.$route.query.conferenceNumber,
        pin: "",
        room: this.$route.query.conferenceRoom || false,
      },
      audioDevices: [],
      videoDevices: [],
      audioMuted: true,
      videoMuted: true,
      myInfo: null,
    };
  },
  watch: {},
  computed: {},
  components: {
    AddressBookList,
    MonitoringList,
    MeetingGroupList,
    SelectedPeople,
    UserList,
  },
  props: {},
  methods: {
    // 离开会议
    endMeeting() {
      this.leaveConference();
      this.$utils.hideLoading();
      this.$router.push({ name: "meetingInitiate" });
    },
    //选择邀请成员
    checkedNodes(arr) {
      this.inviteList = arr;
    },
    // 删除邀请成员
    removeSelected(item) {
      this.inviteList = this.inviteList.filter((t) => t.id != item.id);
      this.$refs.addressBook.removeCheck(this.inviteList);
    },
    // 清空邀请成员
    clearSelected() {
      this.inviteList = [];
      this.$refs.addressBook.clearCheck();
    },
    // 邀请成员
    onInvite() {
      const realm = "umedemo.yealinkops.com";
      const userArr = [];
      const information = apollo.conference.information;
      if (this.inviteList.length > 0) {
        this.inviteList.map((item) => {
          const userInfo = {
            requestUri: item.account + realm,
            uid: item.staffId,
            type: "audio-video",
            band: 1024,
          };
          userArr.push(userInfo);
        });
      }
      information.users.invite(userArr);
      this.inviteVisible = false;
      this.$message({
        message: "邀请成员成功",
        type: "success",
      });
    },
    // 移除参会者
    onKick(item) {
      const information = apollo.conference.information;
      this.$confirm("是否将该成员移出会议？", "提示").then(() => {
        information.users.kick([item.entity]);
      });
    },
    setupEventHandlers(target, eventHandlers) {
      if (!target) {
        return;
      }

      for (const event in eventHandlers) {
        if (Object.prototype.hasOwnProperty.call(eventHandlers, event)) {
          target.on(event, eventHandlers[event]);
        }
      }

      return {};
    },
    getMyInfo(info) {
      const { staffId } = this.$store.state.userInfo;
      const myInfo = info.users.getUser(staffId);
      if (myInfo && myInfo.isCurrentUser()) {
        this.myInfo = myInfo;
      }
    },
    removeEventHandlers(target, eventHandlers) {
      if (!target) {
        return;
      }

      for (const event in eventHandlers) {
        if (Object.prototype.hasOwnProperty.call(eventHandlers, event)) {
          target.removeListener(event, eventHandlers[event]);
        }
      }
    },
    initUserAgent() {
      // *********** UserAgent事件监听 **************
      this.uaHandlers = {
        connected: this.onConnected.bind(this),
        connecting: this.onConnecting.bind(this),
        disconnected: this.onDisconnected.bind(this),
        registered: this.onRegistered.bind(this),
        unregistered: this.onUnregistered.bind(this),
        registrationFailed: this.onRegistrationFailed.bind(this),
      };

      // informationUpdated 为任何会议信息变更都会通知的事件
      // 细分的会议变更事件参考文档说明
      this.confHandlers = {
        connected: this.confConnected.bind(this),
        disconnected: this.confDisconnected.bind(this),
        connectFailed: this.confConnectFailed.bind(this),
        informationUpdated: this.confInformationUpdated.bind(this),
      };

      // 媒体事件监听
      this.mediaHandlers = {
        remoteStreamChanged: this.onRemoteStreamChanged.bind(this),
        localStreamChanged: this.onLocalStreamChanged.bind(this),
      };

      // Set element to display video stream
      this.localVideoElement = document.getElementById("local-video");
      this.remoteVideoElement = document.getElementById("remote-video");

      this.remoteVideoElement.autoplay = true;
      this.remoteVideoElement.onloadedmetadata = () => {
        if (this.remoteVideoElement) {
          this.remoteVideoElement.play();
        }
      };
      this.remoteVideoElement.onpause = () => {
        if (this.remoteVideoElement) {
          this.remoteVideoElement.play();
        }
      };

      this.localVideoElement.autoplay = true;
      this.localVideoElement.onloadedmetadata = () => {
        if (this.localVideoElement) {
          this.localVideoElement.play();
        }
      };
      this.localVideoElement.onpause = () => {
        if (this.localVideoElement) {
          this.localVideoElement.play();
        }
      };

      if (!(global.chrome && global.chrome.app && global.chrome.app.runtime)) {
        const removeEventListener = getPrefixedProperty(
          window,
          "removeEventListener"
        );
        removeEventListener("beforeunload", this.onmounted);
      }

      if (!(global.chrome && global.chrome.app && global.chrome.app.runtime)) {
        const addEventListener = getPrefixedProperty(
          window,
          "addEventListener"
        );

        addEventListener("beforeunload", this.onmounted);
      }
      this.loginYealinkops();
    },
    loginYealinkops() {
      // *********** 账号登录 **************

      // UA的参数涉及到账号的登录，这里仅展示第三方接入时必须设置的参数
      // 其它参数配置参考文档的说明

      const realm = this.$config.yealinkRealm; // -> realm根据服务器配置的信息设置，一般情况下和服务器的域名一致
      const options = {
        uri: `${this.$store.state.userInfo.phone}@${realm}`, // -> uri格式为：username@realm
        ha1: this.$store.state.userInfo.staffA1Hash,
        // password: this.account.password, // -> 账号密码

        user_agent: "Yealink SIP-WEB 1.0.0", // -> 可改为类似格式：Yealink SIP-WEB 1.6.7 (Chrome 78.0)，
        client_info: "Apollo_WebRTC 1.0.0", // -> 推荐默认此设置
        realm: realm, // -> realm根据服务器配置的信息设置，一般情况下和服务器的域名一致（注意：必须为域名）
        servers: 'wss:' + this.$config.yealinkUrl, // -> 替换服务器地址
        socketOptions: {
          // -> 目前推荐使用socketio模式
          type: "socketio",
          rejectUnauthorized: false,
          protocol: "sip",
        },
        sdpSemantics: "unified-plan",
        debug: true, // console打印开启 一般可开启，方便定位问题
      };

      if (apollo.ua) {
        this.removeEventHandlers(apollo.ua, this.uaHandlers);
        apollo.ua.stop();
      }

      // 创建UA
      try {
        apollo.createUA(options);
        this.setupEventHandlers(apollo.ua, this.uaHandlers);
        // 设置UserAgent
        apollo.conference.ua = apollo.ua;
        apollo.conferenceManager.ua = apollo.ua;
        // 启动UA即注册到服务器
        apollo.ua.start();
      } catch (e) {
        console.log(e);
      }
    },
    createConference() {
      // ********** 加入会议 ************

      apollo.conferenceManager.createConference();

      // 设置会议号码和pin
      apollo.conference.number = this.meeting.number;
      apollo.conference.pin = this.meeting.pin;

      // 设置会议事件监听
      this.setupEventHandlers(apollo.conference, this.confHandlers);
      this.setupEventHandlers(
        apollo.conference.mediaChannel,
        this.mediaHandlers
      );

      // 加入会议前需先设置媒体信息
      // 先获取PC当前的音视频输入设备 audioDevices/videoDevices 为已经获取到的设备列表
      // 这里默认都取第一个设备来获取码流，无设备则设置为false
      let constraints = {
        audio: this.audioDevices.length > 0 ? this.audioDevices[0] : false,
        video: this.videoDevices.length > 0 ? this.videoDevices[0] : false,
      };
      if (constraints.video != false) {
        // 指定获取码流的分辨率
        Object.assign(constraints.video, {
          width: 1920,
          height: 1080,
        });
      }
      // 调用入会接口前先获取码流
      // 注：getUserMedia获取码流参数约束查阅官方接口说明
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          // 获取成功后将码流设置到conference.media中
          // constraints则直接取获取到的码流 stream.constraints 设置即可
          apollo.conference.media.constraints = stream.constraints;
          apollo.conference.media.stream = stream || new MediaStream();
        })
        .catch((e) => {
          // 无法获取时（如无设备场景）需要设置空的 MediaStream 对象
          apollo.conference.media.stream = new MediaStream();
        })
        .finally(() => {
          // 加入会议
          apollo.conference.dialIn();
          apollo.conference.on("connected", () => {
            this.$utils.hideLoading();
            // 向后端发送会议室记录
            if (this.meeting.room) {
              const { userInfo } = this.$store.state;
              this.$api.addJoinRecord({
                staffId: userInfo.staffId,
                meetingCode: this.meeting.number,
                meetingPin: this.meeting.pin,
              });
            }
            // 入会是否禁用视频和麦克风（必须设置setTimeout 否则一段时间后会断开连接 原因未知）
            setTimeout(() => {
              if (
                this.$route.query.isUseMicrophone &&
                !JSON.parse(this.$route.query.isUseMicrophone)
              ) {
                this.muteAudio();
              }
              if (
                this.$route.query.isUseCamera &&
                !JSON.parse(this.$route.query.isUseCamera)
              ) {
                this.muteVideo();
              }
            }, 1);
          });
        });
    },
    // 离开会议（离开会议必须要调用disconnect 否则服务器无法知道用户已经离开，导致信息还残留在成员列表）
    leaveConference() {
      if (apollo.conference.media.stream) {
        apollo.conference.media.stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
      apollo.conference.disconnect();
      apollo.ua.stop();
      this.removeEventHandlers(apollo.conference, this.confHandlers);
      this.removeEventHandlers(
        apollo.conference.mediaChannel,
        this.mediaHandlers
      );
    },
    muteAudio() {
      const stream = apollo.conference.media.stream;
      const information = apollo.conference.information;
      const entity = apollo.conference.id;
      information.users
        .setAudioFilter([entity], { egress: !this.audioMuted })
        .then(() => {
          this.audioMuted = !this.audioMuted;
          stream.getTracks().forEach((track) => {
            if (track.kind == "audio") {
              track.enabled = this.audioMuted;
            }
          });
        });
    },
    muteVideo() {
      const stream = apollo.conference.media.stream;
      const information = apollo.conference.information;
      const entity = apollo.conference.id;
      information.users
        .setVideoFilter([entity], { ingress: !this.videoMuted })
        .then(() => {
          this.videoMuted = !this.videoMuted;
          stream.getTracks().forEach((track) => {
            if (track.kind == "video") {
              track.enabled = this.videoMuted;
            }
          });
        });
    },

    // conference event handlers
    confConnected() {
      console.warn("onConnected Conference");
    },
    confDisconnected() {
      console.warn("onDisconnected Conference");
    },
    confConnectFailed(error) {
      error = String(error);
      console.warn("onConnectFailed Conference", error);
      if (error.indexOf("Unknown") > -1) {
        return;
      }
      if (error.indexOf("Call with Pin fail") > -1) {
        this.$utils.errorAlert(
          "当前会议不存在或会议号错误",
          "加入会议失败",
          this.endMeeting()
        );
      } else {
        this.$utils.errorAlert(error, "加入会议失败", this.endMeeting());
      }
    },
    confInformationUpdated(info) {
      this.participantsList = info.users.userList;
      this.getMyInfo(info);

      // 禁言示例
      // info.users.userList[0].setAudioFilter({ingress : false})
      //   .then(() => {
      //     console.warn('setAudioFilter success');
      //   })
      //   .catch((e) => {
      //     console.warn(e);
      //   })
    },

    // mediaChannel event handlers
    onRemoteStreamChanged(stream) {
      console.warn("onRemoteStreamChanged", stream);

      this.remoteStream = stream;
      this.remoteVideoElement.srcObject = this.remoteStream;
    },

    onLocalStreamChanged(stream) {
      console.warn("onLocalStreamChanged", stream);

      this.localStream = stream;
      this.localVideoElement.srcObject = this.localStream;
    },

    // UA event handlers
    onConnected() {
      console.warn("onConnected UserAgent");
    },
    onConnecting() {
      console.warn("onConnecting UserAgent");
    },
    onDisconnected() {
      console.warn("onDisconnected UserAgent");
    },
    onRegistered() {
      console.warn("onRegistered UserAgent");
      // 获取PC当前的媒体设备
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        this.audioDevices = devices.filter(function (dev) {
          return dev.kind == "audioinput";
        });

        this.videoDevices = devices.filter(function (dev) {
          return dev.kind == "videoinput";
        });
        this.createConference();
      });
    },
    onUnregistered() {
      console.warn("onUnregistered UserAgent");
    },
    onRegistrationFailed() {
      console.warn("onRegistrationFailed UserAgent");
    },
  },
  mounted() {
    this.$utils.showLoading({ text: "正在连接中，请稍后..." });
    this.initUserAgent();
  },
  beforeUnmount() {
    this.leaveConference();
  },
};
</script>

<style lang="scss">
.in_meeting {
  position: relative;
  .meeting_room_num {
    background: #202331;
    margin-bottom: 20px;
    padding: 10px 20px;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.6);
  }
  .meeting_room_main {
    background: #202331;
    height: 852px;
    padding: 20px;
    .people_list {
      height: 736px;
      overflow-y: auto;
      overflow-x: hidden;
      ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-right: -40px;
        overflow-y: auto;
        li {
          display: flex;
          align-items: center;
          justify-items: center;
          width: 586px;
          margin-right: 40px;
          margin-bottom: 40px;
          border: 1px solid #464d6f;
          height: 326px;
          text-align: center;
          color: rgba(255, 255, 255, 0.6);
          position: relative;
          transition: all 0.2s ease-out;
          cursor: pointer;
          background: #202331;
          &:hover {
            box-shadow: 0 0 30px 4px rgba(0, 0, 0, 0.6);
          }
          .participantsItem {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            .avatar {
              width: 129px;
              height: 129px;
              background: #30354d;
              border-radius: 100%;
              overflow: hidden;
              margin-top: 80px;
              img {
                width: 100%;
              }
            }
            .item_info_row {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              margin-bottom: 20px;
              .user_name {
                margin-right: 20px;
              }
              .user_phone {
                margin-right: 20px;
              }
            }
          }
          .close {
            position: absolute;
            width: 24px;
            height: 24px;
            background: url("../../assets/icons/close_icon.png") no-repeat;
            background-size: 100%;
            right: 5px;
            top: 5px;
            &:hover {
              transform: scale(1.2);
            }
            &:active {
              opacity: 0.5;
            }
          }
        }
      }
    }

    .tool_panel {
      display: flex;
      padding-top: 20px;
      .tool_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 130px;
        transition: all 0.2s ease-out;
        cursor: pointer;
        &:hover {
          transform: scale(1.1);
          .end_meeting {
            background-color: #e84d4d42;
            color: #e84d4d;
          }
          span {
            color: #fff;
          }
        }
        .icon_tool {
          width: 56px;
          height: 56px;
          display: inline-block;
          margin-bottom: 10px;
          transition: all 0.2s ease-out;
        }
        span {
          color: rgba(255, 255, 255, 0.6);
          font-size: 16px;
          transition: all 0.2s ease-out;
        }
        .icon_mute {
          background: url(../../assets/icons/voice_icon@2x.png);
          background-size: 100%;
        }
        .icon_mute_active {
          background: url(../../assets/icons/voice_icon_active@2x.png);
          background-size: 100%;
        }
        .icon_camera {
          background: url(../../assets/icons/camera_icon@2x.png);
          background-size: 100%;
        }
        .icon_camera_active {
          background: url(../../assets/icons/camera_icon_active@2x.png);
          background-size: 100%;
        }
        .icon_invite {
          background: url(../../assets/icons/invitation_icon@2x.png);
          background-size: 100%;
        }
        .icon_user_list {
          background: url(../../assets/icons/member_icon@2x.png);
          background-size: 100%;
        }
        .icon_more {
          background: url(../../assets/icons/more_icon@2x.png);
          background-size: 100%;
        }
        .end_meeting {
          color: #e84d4d;
          border: 1px solid #e84d4d;
          font-size: 18px;
          padding: 15px 30px;
          margin-top: 20px;
        }
      }
      .active {
        .icon_mute {
          background: url(../../assets/icons/voice_icon_active@2x.png);
          background-size: 100%;
        }
        .icon_camera {
          background: url(../../assets/icons/camera_icon_active@2x.png);
          background-size: 100%;
        }
        .icon_invite {
          background: url(../../assets/icons/invitation_icon_active@2x.png);
          background-size: 100%;
        }
        .icon_user_list {
          background: url(../../assets/icons/member_icon_active@2x.png);
          background-size: 100%;
        }
        .icon_more {
          background: url(../../assets/icons/more_icon_active@2x.png);
          background-size: 100%;
        }
      }
    }
  }
}

.selected_people_main {
  height: 90vh;
  overflow-y: auto;
}

.confirm_row {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  .invite_btn {
    width: 160px;
    height: 44px;
    background: #4a526b;
    border-radius: 6px;
    color: rgba(255, 255, 255, 0.6);
    border: none;
    &:hover {
      background: #404964;
      color: #fff;
    }
  }
  .cancel_btn {
    width: 160px;
    height: 44px;
    border: 2px solid #4a526b;
    border-radius: 6px;
    background: none;
    color: rgba(255, 255, 255, 0.6);
    &:hover {
      color: #fff;
      background: #4049642a;
      border: 2px solid #4a526b;
    }
  }
}

.meeting_room_main_videos {
  position: relative;
  width: 100%;
  height: 100%;
  .remote-video {
    width: 100%;
    height: 100%;
  }
  .local-video {
    position: absolute;
    bottom: 0;
    right: 250px;
    width: 500px;
    height: 280px;
  }
}
.local-video {
  position: absolute;
  width: 100%;
  height: 100%;
}
.el-drawer__body {
  padding: 0;
}
</style>
